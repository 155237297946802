<template>
	<div id="deptlist-wrapper">
		<el-table :data="tableData" border>
			<el-table-column type="expand">
				<template slot-scope="props">
					<el-form label-position="left" inline class="table-expand">
						<el-form-item label="院系编号">
							<span>{{ props.row.deptid }}</span>
						</el-form-item>
						<el-form-item label="院系名称">
							<span>{{ props.row.deptname }}</span>
						</el-form-item>
						<el-form-item label="院系分组">
							<span>{{ props.row.deptgroup }}</span>
						</el-form-item>
						<el-form-item label="院系信息">
							<span>{{ props.row.deptinfo }}</span>
						</el-form-item>
						<el-form-item v-if="props.row.deptcontact" label="联系人 ">
							<span>{{ props.row.deptcontact.name }}</span>
						</el-form-item>
						<el-form-item v-if="props.row.deptcontact" label="联系电话">
							<span>{{ props.row.deptcontact.phone }}</span>
						</el-form-item>
						<el-form-item v-if="props.row.deptcontact" label="联系邮箱">
							<span>{{ props.row.deptcontact.email }}</span>
						</el-form-item>
					</el-form>
				</template>
			</el-table-column>
			<el-table-column prop="deptname" label="院系名称" width="150">
			</el-table-column>
			<el-table-column prop="deptgroup" label="院系分组" width="90">
			</el-table-column>
			<el-table-column prop="deptid" label="院系编号" width="90">
			</el-table-column>
			<el-table-column prop="deptinfo" label="院系信息" min-width="200">
			</el-table-column>
			<el-table-column label="操作" width="146">
				<template slot-scope="scope">
					<!-- 编辑 -->
					<el-tooltip v-if="!deleted" class="item" effect="dark" placement="top">
					<template slot="content">
						编辑该院系的信息
					</template>
					<el-button v-if="!deleted" size="small" icon="el-icon-edit"
						@click="editItem(scope.row)" type="primary" plain circle>
					</el-button>
					</el-tooltip>
					<!-- 删除 -->
					<el-tooltip v-if="!deleted" class="item" effect="dark" placement="top">
					<template slot="content">
						删除该院系——请谨慎操作<br>(可在已删除院系界面恢复)
					</template>
					<el-button v-if="!deleted" size="small" icon="el-icon-delete"
						@click="deleteItem(scope.row)" type="danger" plain circle>
					</el-button>
					</el-tooltip>
					<!-- 重置密码 -->
					<el-tooltip v-if="!deleted" class="item" effect="dark" placement="top">
					<template slot="content">
						重置该院系的密码——请谨慎操作
					</template>
					<el-button v-if="!deleted" size="small" icon="el-icon-key"
						@click="ResetPasswd(scope.row)" type="warning" plain circle>
					</el-button>
					</el-tooltip>
					<!-- 恢复 -->
					<el-tooltip v-if="deleted" class="item" effect="dark" placement="top">
					<template slot="content">
						恢复已删除的该院系——请谨慎操作
					</template>
					<el-button v-if="deleted" size="small" icon="el-icon-refresh-left"
						@click="recoverItem(scope.row)" type="warning" plain circle>
					</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="编辑院系信息" :visible.sync="showEdit">
			<el-form :model="onEditItem">
			<el-form-item label="院系名称" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.deptname" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="院系组别" :label-width="formLabelWidth">
				<el-radio-group v-model="onEditItem.deptgroup">
					<el-radio label="甲"> 甲组 </el-radio>
					<el-radio label="乙"> 乙组 </el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="联系人" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.deptcontact.name" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="联系电话" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.deptcontact.phone" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="联系邮箱" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.deptcontact.email" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="院系介绍" :label-width="formLabelWidth">
				<el-input v-model="onEditItem.deptinfo" autocomplete="off"></el-input>
			</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="quitEdit()">取 消</el-button>
				<el-button type="primary" @click="submitEdit()">确 定</el-button>
			</div>
		</el-dialog>
		<el-backtop>
			<i class="el-icon-caret-top"></i>
		</el-backtop>
	</div>
</template>

<style>
.table-expand {
	font-size: 0;
	width: 88%;
	margin: auto;
}
.table-expand label {
	width: 90px;
	color: #99a9bf;
}
.table-expand .el-form-item {
	margin-right: 0;
	margin-bottom: 0;
	width: 50%;
}
.el-message-box {
	width: 426px;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
	name: "DeptList",

	data() {
		var oneItem = {
			deptid: 0,
			deptname: "",
			deptgroup: "乙",
			deptinfo: "",
			deptcontact: {
				name: "",
				phone: "",
				email: "",
			},
		};
		return {
			deleted: this.$route.query.deleted === "true" ? true : false,
			tableData: [],
			onEditItem: oneItem,  // the item on edit
			preEditItem: oneItem,  // original content of the item which is being editing
			showEdit: false,  // show the edit dialog
			formLabelWidth: '120px',
		};
	},
	watch: {
		$route() {
			this.deleted = (this.$route.query.deleted === "true" ? true : false);
			this.getDeptList();
		}
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
		},
		editItem(row) {
			// console.log(row);
			this.showEdit = true;
			if (row.deptid != this.preEditItem.deptid) {
				this.preEditItem = JSON.parse(JSON.stringify(row));
				this.onEditItem = JSON.parse(JSON.stringify(row));
			}
		},
		submitEditCheck() {
			if (!this.onEditItem.deptid) {
				this.messageBox("请选择要修改的院系", "error");
				return false;
			}
			if (!this.onEditItem.deptname) {
				this.messageBox("请填写院系名称", "error");
				return false;
			}
			if (!this.onEditItem.deptgroup) {
				this.messageBox("请选择院系类别", "error");
				return false;
			}
			if (
				!this.onEditItem.deptcontact.name &&
				!this.onEditItem.deptcontact.phone &&
				!this.onEditItem.deptcontact.email )
			{
				this.messageBox("请补全联系人信息", "error");
				return false;
			}
			return true;
		},
		submitEdit() {
			if (this.submitEditCheck() === true) {
			this.$confirm("此操作将修改"+this.preEditItem.deptname+"的信息, 是否提交当前修改？", "提示", {
				confirmButtonText: "确认",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(() => {
				// console.log(this.onEditItem);
				if (localStorage.getItem("token")) {
					axios.defaults.headers.common["Authorization"] = localStorage.getItem(
						"token"
					);
				}
				axios({
					url: "/api/admin/asso/update",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						type: "dept",
						id: this.onEditItem.deptid,
						deptname: this.onEditItem.deptname,
						deptgroup: this.onEditItem.deptgroup,
						deptinfo: this.onEditItem.deptinfo,
						deptcontact: JSON.stringify({
							name: this.onEditItem.deptcontact.name,
							phone: this.onEditItem.deptcontact.phone,
							email: this.onEditItem.deptcontact.email,
						}),
					}),
				})
				.then(async () => {
					// console.log(res);
					this.showEdit = false;
					this.messageBox("修改" + this.onEditItem.deptname + "信息成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"修改失败， " +
							err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Update dept Failed");
					console.log(err.response);
				});
			})
			.catch(() => {})}
		},
		quitEdit() {
			this.$confirm("此操作将退出修改页面, 是否暂存当前修改？<br><span style=\"font-size: smaller;\">（刷新页面或修改其他院系时清空；点击右上叉号返回继续修改）</span>", "提示", {
				confirmButtonText: "暂存修改并退出",
				cancelButtonText: "撤销修改并退出",
				type: "warning",
				distinguishCancelAndClose: true,
				dangerouslyUseHTMLString: true,
			})
			.then(() => {
				this.showEdit = false;
			})
			.catch((action) => {
				if (action === 'cancel') {
					this.showEdit = false;
					this.onEditItem = JSON.parse(JSON.stringify(this.preEditItem));
				}
			});
		},
		deleteItem(row) {
			this.$confirm("此操作将删除该院系, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(() => {
				if (row.deptid != undefined) {
				axios({
					url: "/api/admin/asso/delete",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						type: "dept",
						id: row.deptid,
					}),
				})
				.then(async () => {
					// console.log(res);
					await this.messageBox("删除院系成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"删除院系失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Delete Dept Failed");
					console.log(err.response);
				});
				} else {
					this.messageBox("获取院系信息失败，请检查网络连接或刷新界面", "error");
				}
			}).catch(() => {});
		},
		ResetPasswd(row) {
			this.$prompt('该操作会重置'+row.deptname+'的密码，请谨慎操作！<br>在下方输入新密码：', '重置密码', {
				type: 'warning',
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPlaceholder: '新密码',
				inputPattern: /^.{6,30}$/,
				inputErrorMessage: '请输入密码，长度为6至30个字符',
			})
			.then(({ value }) => {
				// console.log(value);
				axios({
					url: "/api/admin/asso/passwd",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						type: 'dept',
						id: row.deptid,
						password: value,
					}),
				})
				.then(async () => {
					// console.log(res);
					await this.messageBox("重置"+row.deptname+"密码成功，请提醒该院系查看", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"重置密码失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Reset Password Failed");
					console.log(err.response);
				});
			}).catch(() => {});
		},
		recoverItem(row) {
			this.$confirm("此操作将恢复该院系, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(() => {
				if (row.deptid != undefined) {
				axios({
					url: "/api/admin/asso/recover",
					method: "POST",
					headers: {
						"content-type": "application/x-www-form-urlencoded",
					},
					data: qs.stringify({
						type: "dept",
						id: row.deptid,
					}),
				})
				.then(async () => {
					// console.log(res);
					await this.messageBox("恢复院系成功", "success");
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					this.messageBox(
						"恢复院系失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Delete dept Failed");
					console.log(err.response);
				});
				} else {
					this.messageBox("获取院系信息失败，请检查网络连接或刷新界面", "error");
				}
			}).catch(() => {});
		},
		getDeptList() {
			// Return the list of departments, the parameter deleted is default 0, means not deleted; 1 for have deleted
			axios({
				url: "/api/admin/asso/get",
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
				params: {
					type: "dept",
					deleted: (this.deleted === true ? 1 : 0),
				},
			})
				.then((res) => {
					this.tableData = res.data.data;
				})
				.catch((err) => {
					this.messageBox(
						"获取院系列表失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Get dept list Failed");
					console.log(err.response);
					return -1;
				});
		},
	},
	mounted() {
		this.getDeptList();
	},
};
</script>
